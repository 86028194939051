const defaultConfig = {
    env: 'development', // 本地开发环境
    // 接口域名配置
    domain: {
        httpUrl:'https://api.lingyun.ustep.cn',
        wssUrl:'wss://api.lingyun.ustep.cn/ws',
    },
};
const config = {
    test:{
        env: 'test',
        // 域名配置
        domain: {
            httpUrl:'https://api.lingyun.ustep.cn',
            wssUrl:'wss://api.lingyun.ustep.cn/ws',
        }
    },
    production:{
        env: 'production',
        domain:{
            httpUrl:'https://api.lingyun.ustep.cn',
            wssUrl:'wss://api.lingyun.ustep.cn/ws',
        }
    }
}

// 根据环境变量 配置环境参数, 默认是本地开发环境
const env = process.env.NODE_ENV;
let envConfig = defaultConfig;
switch (env) {
    case 'test':
        envConfig = config.test;
        break;
    case 'production':
        envConfig = config.production;
        break;
}
export const  appConfig = envConfig




