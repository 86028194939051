import axios from 'axios'
import { SYSTEM_RESPONSE_MESSAGE } from './request-enum'
// import Cookies from 'js-cookie'
import {Message} from "iview";
import router from "@/router";
// 创建axios实例
const instance = axios.create({
    timeout: 60000
});
instance.interceptors.request.use(
     (config) => {
         const token = sessionStorage.getItem('token') || ""; // 获取本地token
         config.headers["User-Token"] = token; // 在headers里加入token
         return config;
    },
     (error) => {
         return Promise.reject(error);
    }
);
instance.interceptors.response.use(
    (response) => {
         return response
    },
    (error) => {
        // 返回接口返回的错误信息
        if(error && error.response){
            switch (error.response.status){
                case 400:
                    error.message = SYSTEM_RESPONSE_MESSAGE.REQUEST_ERROR; //请求错误(400)
                    break;
                case 401:
                    error.message = SYSTEM_RESPONSE_MESSAGE.LOGIN_EXPIRE; //登录已过期(401)
                    Message.error('您的登录已经过期，请重新登录！')
                    // Cookies.remove('token')
                    sessionStorage.removeItem('token')
                    router.push('/login').then()
                    break;
                case 403:
                    error.message = SYSTEM_RESPONSE_MESSAGE.NO_ACCESS_RIGHTS; //无访问权限(403)
                    break;
                case 404:
                    error.message = SYSTEM_RESPONSE_MESSAGE.ACCESS_ERROR; //请求出错(404)
                    break;
                case 408:
                    error.message = SYSTEM_RESPONSE_MESSAGE.THE_REQUEST_TIMEOUT; //请求超时(408)
                    break;
                case 500:
                    error.message = SYSTEM_RESPONSE_MESSAGE.SERVER_ERROR; //服务器错误(500)
                    break;
                case 501:
                    error.message = SYSTEM_RESPONSE_MESSAGE.SERVICE_NOT_IMPLEMENTED; //服务未实现(501)
                    break;
                case 502:
                    error.message = SYSTEM_RESPONSE_MESSAGE.NETWORK_ERROR; //网络错误(502)
                    break;
                case 503:
                    error.message = SYSTEM_RESPONSE_MESSAGE.SERVICE_UNAVAILABLE; //服务不可用(503)
                    break;
                case 504:
                    error.message = SYSTEM_RESPONSE_MESSAGE.NETWORK_TIMEOUT; //网络超时(504)
                    break;
                case 505:
                    error.message = SYSTEM_RESPONSE_MESSAGE.THE_HTTP_VERSION_IS_NOT_SUPPORTED; //HTTP版本不受支持(505)
                    break;
                default:
                    error.message = SYSTEM_RESPONSE_MESSAGE.CONNECTION_ERROR+`(${error.response.status})!`; //连接出错 status 未知
            }
        }else {
            error.message = SYSTEM_RESPONSE_MESSAGE.FAILED_TO_CONNECT_TO_SERVER //连接服务器失败
        }
        return Promise.reject(error)
    }
);

export default {

    /**
     * get 请求
     * @param url
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    get( url, params){
        return instance.get(url,params)
    },
    /**
     * post 请求， 使用JSON数据方式 调用接口
     * @param url
     * @param data
     * @returns {*}
     */
    postJson( url, data){
        const conf = {
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
            }
        }
        return instance.post(url, data,conf);
    },
    /**
     * post 请求， 上传 调用接口
     * @param url
     * @param data
     * @returns {*}
     */
    postUpLoad( url, data){
        const conf = {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }
        return instance.post(url, data,conf);
    }
}
