/**
 * 登录验证提示语
 * @type {{USERNAME_OR_PASSWORD_FREE_ERROR: string}}
 */
export const LOGIN_CHECK_MESSAGE = {
    USERNAME_OR_PASSWORD_FREE_ERROR:'用户名或密码错误！', //登录验证提示
    LOGIN_SUCCESSFUL:'欢迎登录禹步信息live平台！',
}
/**
 * 登录方式状态验证
 * @type {{ACCOUNT_MODE: string, VERIFICATION_CODE_MODE: string}}
 */
export const LOGIN_MODE = {
    ACCOUNT_MODE:'ACCOUNT_MODE',  //账户登录
    VERIFICATION_CODE_MODE:'VERIFICATION_CODE_MODE' //验证码登录
}

/**
 * footer 显示状态
 * @type {{Object}}
 */
export const USER_BAR_STATUS = {
    CONTACTS:'CONTACTS', //联系人
    CHAT_RECORD:'CHAT_RECORD', //聊天记录
    ME:'ME',  //我的
}

/**
 * 在线状态
 */
export const ONLINE_STATUS = {
    ONLINE:'ONLINE',  //在线
    OFFLINE:'OFFLINE',//离线
    BUSY:'BUSY' //忙碌
}

/**
 * 线条
 */
// export const lineStroke = [
//     {
//         stroke:2,
//         img:line0
//     },
//     {
//         stroke:4,
//         img:line1
//     },
//     {
//         stroke:6,
//         img:line2
//     },
//     {
//         stroke:8,
//         img:line3
//     }
// ]
/**
 * 拾色器
 * @param name
 */
export const colorPicker = [
    {
        name:'red',
        color:'#FF0000'
    },
    {
        name:'yellow',
        color:'#FFFF00'
    },
    {
        name:'green',
        color:'#00FF00'
    },
    {
        name:'blue',
        color:'#378efb'
    },
]




